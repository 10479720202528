import React from 'react';
import { Helmet } from 'react-helmet';

interface IPageHead {
  title: string;
  description?: string;
  keywords?: string;
  meta?: { name: string; content: string }[];
}

export const PageHead = (props: IPageHead): React.ReactElement => {
  const { title, description, keywords, meta } = props;
  const IMAGE_ID = '1GNFRpCQWbY-r8BOwwzyCUMVbwdZ0HVn5';
  const IMAGE_URL = `https://drive.google.com/uc?id=${IMAGE_ID}`;
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <title key="title">{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1,shrink-to-fit=no" />
      <meta key="og:title" property="og:title" content={title?.trim()} />
      <meta key="description" name="description" content={description?.trim()} />
      <meta key="keywords" name="keywords" content={keywords?.trim()} />
      {/* Tags for Social Media */}
      {/* TODO: Replace Image URLs */}
      <meta property="og:title" content={title.trim()} />
      <meta property="og:description" content={description?.trim()} />
      <meta property="og:image" content={IMAGE_URL} />
      <meta property="og:url" content={IMAGE_URL} />
      <meta property="og:type" content="website" />
      {/* Tags for Twitter */}
      <meta name="twitter:title" content={title.trim()} />
      <meta name="twitter:description" content={description?.trim()} />
      <meta name="twitter:card" content={IMAGE_URL} />
      <meta name="twitter:image" content={IMAGE_URL} />
      {meta?.map(m => (
        <meta key={m.name} {...m} />
      ))}
    </Helmet>
  );
};

import React, { ReactElement, useContext, useRef } from 'react';
import { AiOutlineHome } from '@react-icons/all-files/ai/AiOutlineHome';
import { AiOutlineInfoCircle } from '@react-icons/all-files/ai/AiOutlineInfoCircle';
import { AiOutlineProject } from '@react-icons/all-files/ai/AiOutlineProject';
import { AiOutlineUser } from '@react-icons/all-files/ai/AiOutlineUser';
import { GiJourney } from '@react-icons/all-files/gi/GiJourney';
import { GiStack } from '@react-icons/all-files/gi/GiStack';
import { IoMdMoon } from '@react-icons/all-files/io/IoMdMoon';
import { IoMdSunny } from '@react-icons/all-files/io/IoMdSunny';

import { EVENTS, GAservice } from '@/utils/analytics.helper';
import {
  ABOUT_SECTION_REF,
  CONTACT_SECTION_REF,
  getActiveSection,
  HOME_SECTION_REF,
  JOURNEY_SECTION_REF,
  PROJECT_SECTION_REF,
  SECTION_IDs,
  IAvailableSections,
  TECH_STACK_SECTION_REF,
} from '@/utils/sections.helper';
import { THEME_CONTEXT } from '@/utils/theme.helper';

import styles from './nav.module.scss';

const Nav = (): ReactElement => {
  const activeSection = getActiveSection();
  const isInitialRender = useRef<boolean>(true);
  const shouldShowHeader = activeSection !== 'home';
  if (shouldShowHeader) {
    isInitialRender.current = false;
  }
  const { isDarkTheme, onThemeChange } = useContext(THEME_CONTEXT);

  const handleClick = (toSection: IAvailableSections) => {
    GAservice.publish({ ...EVENTS.section_scroll, label: `clicked: ${toSection} section` });
    switch (toSection) {
      case 'home':
        HOME_SECTION_REF.current?.scrollIntoView();
        break;
      case 'about':
        ABOUT_SECTION_REF.current?.scrollIntoView();
        break;
      case 'tech-stack':
        TECH_STACK_SECTION_REF.current?.scrollIntoView();
        break;
      case 'journey':
        JOURNEY_SECTION_REF.current?.scrollIntoView();
        break;
      case 'project':
        PROJECT_SECTION_REF.current?.scrollIntoView();
        break;
      case 'contact':
        CONTACT_SECTION_REF.current?.scrollIntoView();
        break;
      default:
        break;
    }
  };

  return (
    <nav className={`${styles.main} ${!isInitialRender.current ? (shouldShowHeader ? styles.animate_in : styles.animate_out) : ''}`}>
      <button
        aria-label="Go to Home Section"
        onClick={() => handleClick('home')}
        className={activeSection === SECTION_IDs.home ? styles.buttonActive : ''}
      >
        <AiOutlineHome />
      </button>
      <button
        aria-label="Go to About section"
        onClick={() => handleClick('about')}
        className={activeSection === SECTION_IDs.about ? styles.buttonActive : ''}
      >
        <AiOutlineInfoCircle />
      </button>
      <button
        aria-label="Go to Tech-stack section"
        onClick={() => handleClick('tech-stack')}
        className={activeSection === SECTION_IDs['tech-stack'] ? styles.buttonActive : ''}
      >
        <GiStack />
      </button>
      <button
        aria-label="Go to Journey section"
        onClick={() => handleClick('journey')}
        className={activeSection === SECTION_IDs.journey ? styles.buttonActive : ''}
      >
        <GiJourney />
      </button>
      <button
        aria-label="Go to Projects section"
        onClick={() => handleClick('project')}
        className={activeSection === SECTION_IDs.project ? styles.buttonActive : ''}
      >
        <AiOutlineProject />
      </button>
      <button
        aria-label="Go to Contact section"
        onClick={() => handleClick('contact')}
        className={activeSection === SECTION_IDs.contact ? styles.buttonActive : ''}
      >
        <AiOutlineUser />
      </button>
      <button
        aria-label="Toggle Theme"
        onClick={() => {
          onThemeChange(!isDarkTheme);
          GAservice.publish({ ...EVENTS.theme_toggle, label: `Toggled theme to: ${!isDarkTheme ? 'dark' : 'light'}` });
        }}
      >
        {isDarkTheme ? <IoMdSunny /> : <IoMdMoon />}
      </button>
    </nav>
  );
};

export { Nav };

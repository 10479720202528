import React, { ReactElement, useMemo } from 'react';
import { useThemeHelper } from '@/utils/theme.helper';
import { Image } from '../image/image.component';
import { PageHead } from '../seo/seo.component';

import styles from './404.module.scss';

export const PageNotFound = (): ReactElement => {
  const { isDarkTheme } = useThemeHelper();
  const DARK_MODE_VAR = {
    backgroundImage: '404-bg-dark.jpg',
    foregroundImage: 'dark-wolf.jpg',
    title: `Looks like you are lost here`,
    subtitle: (
      <h4 className={styles.subTitle}>
        Some wild <span>predators</span> might be there with you 🐺 !
      </h4>
    ),
    endText: (
      <p className={styles.infoText}>
        it's good to have some adventures in life, but <span>not</span> this time
      </p>
    ),
    buttonTitle: 'Take me home 😰',
  };

  const LIGHT_MODE_VAR = {
    backgroundImage: '404-bg.jpg',
    foregroundImage: 'white-bear.png',
    title: `You've landed in the middle of nowhere`,
    subtitle: <h4 className={styles.subTitle}>and these bears are worried for you 🐻 !</h4>,
    endText: <p className={styles.infoText}>It is a great place to be, but not this time</p>,
    buttonTitle: 'Take me home',
  };

  const data = useMemo(() => (isDarkTheme ? DARK_MODE_VAR : LIGHT_MODE_VAR), [isDarkTheme]);
  return (
    <div className={styles.container}>
      <PageHead title={'Lost somewhere !'} />
      <Image src={data.backgroundImage} className={styles.image} />
      <div className={isDarkTheme ? styles.dialogContainerDark : styles.dialogContainer}>
        <h3>{data.title}</h3>
        {data.subtitle}
        <Image src={data.foregroundImage} className={styles.foreground_image} />
        {data.endText}
        <a href="/">{data.buttonTitle}</a>
      </div>
    </div>
  );
};

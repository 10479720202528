import React, { ChangeEvent, ReactElement } from 'react';
import styles from './input.module.scss';

interface IInput {
  label: string;
  value: string;
  name: string;
  placeholder?: string;
  errorMessage?: string;
  containerClass?: string;
  inputClass?: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const Input = (props: IInput): ReactElement => {
  const { label, name, placeholder, errorMessage, containerClass = '', inputClass = '', value, onChange } = props;
  return (
    <section className={`${styles.input_container} ${containerClass}`}>
      <label>{label}</label>
      <input name={name} value={value} placeholder={placeholder} className={`${styles.base_input} ${inputClass}`} onChange={onChange} />
      <p className={styles.error_message}>{errorMessage}</p>
    </section>
  );
};

const TextArea = (props: IInput): ReactElement => {
  const { label, name, placeholder, errorMessage, containerClass = '', inputClass = '', value, onChange } = props;
  return (
    <section className={`${styles.input_container} ${containerClass}`}>
      <label>{label}</label>
      <textarea
        name={name}
        value={value}
        placeholder={placeholder}
        className={`${styles.base_textfield} ${inputClass}`}
        onChange={onChange}
      />
      <p className={styles.error_message}>{errorMessage}</p>
    </section>
  );
};

Input.Textarea = TextArea;

export { Input };

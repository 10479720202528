import React, { ReactElement, useRef } from 'react';
import { useAnimationHelper } from '@/utils/sections.helper';
import styles from './title.module.scss';

interface ITitle {
  title: string;
  reverse?: boolean;
}
export const Title = (props: ITitle): ReactElement => {
  const { title, reverse = false } = props;
  const sectionRef = useRef<any>();
  const shouldAnimate = useAnimationHelper(sectionRef, { rootMargin: '0% 0% -20% 0%' });
  const titleClassName = shouldAnimate ? (reverse ? styles.animate_in_title_right : styles.animate_in_title_left) : '';
  const barClassName = shouldAnimate ? (reverse ? styles.animate_in_bar_right : styles.animate_in_bar_left) : '';
  return (
    <section ref={sectionRef} className={styles.container}>
      <h1 className={`${styles.section_title} ${titleClassName}`}>{title}</h1>
      <div className={`${styles.horizontal_bar} ${barClassName}`} />
    </section>
  );
};

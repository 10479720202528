import React, { RefObject, useEffect, useState, useRef } from 'react';

export const HOME_SECTION_REF: RefObject<HTMLElement> = React.createRef();
export const ABOUT_SECTION_REF: RefObject<HTMLElement> = React.createRef();
export const JOURNEY_SECTION_REF: RefObject<HTMLElement> = React.createRef();
export const TECH_STACK_SECTION_REF: RefObject<HTMLElement> = React.createRef();
export const PROJECT_SECTION_REF: RefObject<HTMLElement> = React.createRef();
export const CONTACT_SECTION_REF: RefObject<HTMLElement> = React.createRef();

export const SECTION_IDs = {
  home: 'home',
  about: 'about',
  'tech-stack': 'tech-stack',
  journey: 'journey',
  project: 'project',
  contact: 'contact',
} as const;

export type IAvailableSections = keyof typeof SECTION_IDs;

interface Args extends IntersectionObserverInit {
  freezeOnceVisible?: boolean;
}

const DEFAULT_OBSERVER_OPTIONS: Args = {
  threshold: 0,
  root: null,
  rootMargin: '0% 0% -100% 0%',
  freezeOnceVisible: false,
};

export const useIntersectionObserver = (
  elementRef: RefObject<Element>,
  options: Args = DEFAULT_OBSERVER_OPTIONS,
): IntersectionObserverEntry | undefined => {
  const { threshold, root, rootMargin, freezeOnceVisible } = options;
  const [entry, setEntry] = useState<IntersectionObserverEntry>();
  const frozen = entry?.isIntersecting && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || frozen || !node) return;

    const observerParams = { threshold, root, rootMargin };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen]);

  return entry;
};

export const getActiveSection = (): IAvailableSections | Omit<string, IAvailableSections> => {
  const [activeSection, setActiveSection] = useState<string>(SECTION_IDs.home);
  const AVAILABLE_SECTIONs = [
    HOME_SECTION_REF,
    ABOUT_SECTION_REF,
    JOURNEY_SECTION_REF,
    TECH_STACK_SECTION_REF,
    PROJECT_SECTION_REF,
    CONTACT_SECTION_REF,
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: `0% 0% -100% 0%` },
    );

    AVAILABLE_SECTIONs.forEach(section => {
      const node = section?.current;
      if (node) {
        observer.observe(node as Element);
      }
    });

    return () => {
      AVAILABLE_SECTIONs.forEach(section => {
        const node = section?.current;
        if (node) observer.unobserve(node);
      });
    };
  }, []);

  return activeSection;
};

export const useAnimationHelper = (elementRef: RefObject<Element>, options: Args = DEFAULT_OBSERVER_OPTIONS): boolean => {
  const isVisible = useRef<boolean>(false);
  const shouldAnimate = useIntersectionObserver(elementRef, options)?.isIntersecting;
  if (!isVisible.current && shouldAnimate) {
    isVisible.current = true;
  }
  return isVisible.current;
};

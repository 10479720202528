type IEvent = {
  category: string;
  action: string;
  label?: string;
  value?: string;
};

export const EVENTS = {
  terminal_command: {
    category: 'Terminal Command',
    action: 'scroll | gif | display',
    label: '<command_name>',
  },
  project_actions: {
    category: 'Project Actions',
    action: 'click | filter',
    label: '<click/filter name>',
  },
  call_to_action: {
    category: 'Action Button',
    action: 'CTA click',
    label: '<button_title>',
  },
  social_media: {
    category: 'Social Media',
    action: 'social profile visit',
    label: '<social_name>',
  },
  section_scroll: {
    category: 'Section Click',
    action: 'section scroll',
    label: '<section name>',
  },
  theme_toggle: {
    category: 'Theme',
    action: 'theme toggle',
    label: '<toggled to: >',
  },
};

const publish = (eventToPublish: IEvent): void => {
  (window as any)?.gtag?.('event', eventToPublish.action, eventToPublish);
};

export const GAservice = {
  publish,
};

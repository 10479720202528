import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

interface IImage {
  src: string;
  className?: string;
}

export const Image = ({ src, ...props }: IImage): ReactElement | null => {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid {
            originalName
          }
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
        }
      }
    }
  `);

  const match = data.allImageSharp.nodes.find((node: any) => node.fluid?.originalName?.match(src));
  const image = getImage(match?.gatsbyImageData) as any;
  const isValid = match?.fluid.originalName;
  return isValid ? <GatsbyImage image={image} alt={`${src}'s Logo`} {...props} /> : null;
};

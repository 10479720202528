import { createContext, useState } from 'react';

type iThemeHelper = {
  onThemeChange: (e: boolean) => void;
  isDarkTheme: boolean;
};

const getTheme = (): string | undefined => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return (window as any).__theme;
};

const setPreferredTheme = (theme: 'light' | 'dark'): void => {
  (window as any).__setPreferredTheme(theme);
};

export const useThemeHelper = (): iThemeHelper => {
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(getTheme() === 'dark');

  const onChange = (isChecked: boolean) => {
    setIsDarkTheme(isChecked);
    setPreferredTheme(isChecked ? 'dark' : 'light');
  };

  return { onThemeChange: onChange, isDarkTheme };
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const THEME_CONTEXT = createContext({ isDarkTheme: getTheme() === 'dark', onThemeChange: (isChecked: boolean) => {} });
